<template>
  <b-container
    v-bind:class="{ 'p-0': isIFrame }"
    :fluid="isIFrame || (event.chatIframe && event.state == 'live')"
    v-if="event"
    class="event"
  >
  
    <b-row v-if="!isIFrame" class="my-2">
      <b-col>
        <h2 class="text-center" v-if="event.title && !event.hideTitle ? true : false">
          {{ event ? t(event.title) : "" }}
        </h2>
      </b-col>
    </b-row>
    <b-row v-if="!isIFrame" class="my-2">
      <b-col>
        <b-alert class="message" show v-if="t(event.generic_message)">{{
          t(event.generic_message)
        }}</b-alert>
      </b-col>
    </b-row>
    <b-row
      v-if="state == 'coming' && event.coming && !chatOnly"
      v-bind:class="{ 'my-2': !isIFrame }"
    >
      <b-col>
        <div v-if="state == 'coming' && event.coming" class="coming">
          <b-card
            :overlay="event.coming.backgroundImage ? true : false"
            :img-src="t(event.coming.backgroundImage)"
            v-bind:style="{
                          'background-color': event.cardBackgroundColor
                            ? event.cardBackgroundColor
                            : '#ffffff',
                          'border-color': event.cardBorderColor 
                            ? event.cardBorderColor 
                            : ''
                        }"
          >
            <b-card-text
              ><b-aspect class="coming-message" aspect="16:9">
                <b-container class="h-100">
                  <b-row class="h-100" align-v="center">
                    <b-col
                      class="text-center"
                      align-self="center"
                      ><h3 v-if="t(event.coming.message)"
                        v-bind:style="{
                          color: event.coming.textColor
                            ? event.coming.textColor
                            : '#000000',
                        }"
                      >
                        {{ t(event.coming.message) }}
                      </h3>
                    </b-col>
                  </b-row>
                </b-container>
              </b-aspect>
            </b-card-text>
          </b-card>
        </div>
      </b-col>
    </b-row>

    <b-row
      v-if="state == 'coming' && event.coming && event.coming.countdown"
      v-bind:class="{ 'my-2': !isIFrame }"
    >
      <b-col>
        <div v-if="state == 'coming' && event.coming" class="coming">
          <b-card class="text-center"
            v-bind:style="{
                          'background-color': event.cardBackgroundColor
                            ? event.cardBackgroundColor
                            : '#ffffff',
                          'border-color': event.cardBorderColor 
                            ? event.cardBorderColor 
                            : '',
                          'color': event.coming.textColor
                            ? event.coming.textColor
                            : '#000000',
                        }"
          >
            <b-card-text
              >
              <h2><countdown v-if="event.coming.countdown" :endDate="event.coming.countdown" :countdownMessage="t(event.coming.countdownMessage)" :endedMessage="t(event.coming.countdownEndedMessage)"/></h2>
            </b-card-text>
          </b-card>
        </div>
      </b-col>
    </b-row>


    <b-row>
      <b-col>
        <div v-if="event.requirePII && !user.PII" class="coming">
          <b-card v-bind:style="{
                          'background-color': event.cardBackgroundColor
                            ? event.cardBackgroundColor
                            : '#ffffff',
                          'border-color': event.cardBorderColor 
                            ? event.cardBorderColor 
                            : ''
                        }">
            <b-card-text
              ><b-aspect class="coming-message" aspect="3:1">
                <b-container class="h-100">
                  <b-row class="h-100" align-v="center">
                    <b-col class="text-center" align-self="center"
                      ><h4>
                        {{
                          t(event.PIIprivacy.registrationtext) ||
                          "Registration is required"
                        }}
                      </h4>
                      <hr />
                      <b-form
                        class="mt-4"
                        @submit.prevent="updateUsersData(true)"
                      >
                        <b-row>
                          <b-col md="6">
                            <b-form-group id="input-group-2">
                              <b-form-input
                                id="input-2"
                                v-model="formPII.name"
                                :placeholder="
                                  t(event.PIIprivacy.name) || 'Name'
                                "
                                required
                                autocomplete="given-name"
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group id="input-group-3">
                              <b-form-input
                                id="input-3"
                                v-model="formPII.surname"
                                :placeholder="
                                  t(event.PIIprivacy.surname) || 'Surname'
                                "
                                required
                                autocomplete="family-name"
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col md="12">
                            <b-form-group id="input-group-1">
                              <b-form-input
                                id="input-1"
                                v-model="formPII.email"
                                type="email"
                                :placeholder="
                                  t(event.PIIprivacy.email) || 'Email'
                                "
                                required
                                autocomplete="email"
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col md="12">
                            <b-form-group id="input-group-extrafield1">
                              <b-form-input
                                v-if="
                                  event.PIIextrafields &&
                                  t(event.PIIextrafields.extrafield1)
                                "
                                id="input-extra1"
                                v-model="formPII.extrafield1"
                                type="text"
                                :placeholder="
                                  t(event.PIIextrafields.extrafield1) ||
                                  'Extra 1'
                                "
                                required
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col md="12">
                            <b-form-group id="input-group-extrafield2">
                              <b-form-input
                                v-if="
                                  event.PIIextrafields &&
                                  t(event.PIIextrafields.extrafield2)
                                "
                                id="input-extra2"
                                v-model="formPII.extrafield2"
                                type="text"
                                :placeholder="
                                  t(event.PIIextrafields.extrafield2) ||
                                  'Extra 2'
                                "
                                required
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col md="12">
                            <b-form-group id="input-group-extrafield3">
                              <b-form-input
                                v-if="
                                  event.PIIextrafields &&
                                  t(event.PIIextrafields.extrafield3)
                                "
                                id="input-extra3"
                                v-model="formPII.extrafield3"
                                type="text"
                                :placeholder="
                                  t(event.PIIextrafields.extrafield3) ||
                                  'Extra 3'
                                "
                                required
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col md="12">
                            <b-form-group id="input-group-extrafield4">
                              <b-form-input
                                v-if="
                                  event.PIIextrafields &&
                                  t(event.PIIextrafields.extrafield4)
                                "
                                id="input-extra4"
                                v-model="formPII.extrafield4"
                                type="text"
                                :placeholder="
                                  t(event.PIIextrafields.extrafield4) ||
                                  'Extra 4'
                                "
                                required
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col md="12">
                            <b-form-group id="input-group-extrafield5">
                              <b-form-input
                                v-if="
                                  event.PIIextrafields &&
                                  t(event.PIIextrafields.extrafield5)
                                "
                                id="input-extra5"
                                v-model="formPII.extrafield5"
                                type="text"
                                :placeholder="
                                  t(event.PIIextrafields.extrafield5) ||
                                  'Extra 5'
                                "
                                required
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-col md="12" v-if="event.PIIprivacy.requirePrivacyCheckbox">
                          <b-alert show variant="light" style="border: none" v-bind:style="{
                          'background-color': event.cardBackgroundColor
                            ? event.cardBackgroundColor
                            : '#ffffff',
                          
                        }"
                            >
                            <p v-if="event.PIIprivacy.embeddedPrivacyEnabled" class="mb-3">
                              <b-button :variant="event.PIIprivacy.embeddedPrivacyButtonVariant || 'light'" @click="$refs['embeddedPrivacyModal'].show()" size="sm">{{ t(event.PIIprivacy.embeddedPrivacyClickToOpenMessage) || "Click to read and accept the privacy policy" }}</b-button>
                            </p>
                            <b-form-checkbox
                              id="privacyPolicyAccepted"
                              v-model="formPII.privacyPolicyAccepted"
                              name="privacyPolicyAccepted"
                              :value=true
                              :unchecked-value=false
                              :disabled="event.PIIprivacy.embeddedPrivacyEnabled"
                              required
                              v-if="(formPII.privacyPolicyAccepted && event.PIIprivacy.embeddedPrivacyEnabled) || !event.PIIprivacy.embeddedPrivacyEnabled"

                            >
                              <small>{{ t(event.PIIprivacy.text) }}</small>
                            </b-form-checkbox>
                            <p v-if="event.PIIprivacy.linkURL" class="mb-0">
                              <a target="_blank" :href="t(event.PIIprivacy.linkURL)">{{
                                t(event.PIIprivacy.linkText) ||
                                t(event.PIIprivacy.linkURL)
                              }}</a>
                            </p>
                            <b-modal ref="embeddedPrivacyModal" size="lg" hide-footer :title="t(event.PIIprivacy.embeddedPrivacyTitle) || 'Privacy Policy - Scroll to accept'">
                              <div class="d-block text-center">
                                <div class="text-left" v-html="t(event.PIIprivacy.embeddedPrivacy)"></div>
                              </div>
                              <b-button class="mt-3" variant="success" block @click="acceptPrivacy()">{{ t(event.PIIprivacy.embeddedPrivacyAcceptButton) || "Accept" }}</b-button>
                              <b-button class="mt-2" variant="danger" block @click="rejectPrivacy()">{{ t(event.PIIprivacy.embeddedPrivacyRejectButton) || "Reject" }}</b-button>
                            </b-modal>
                            </b-alert>
                        </b-col>
                        <div class="text-center">
                          <b-button v-if="(event.PIIprivacy.requirePrivacyCheckbox && formPII.privacyPolicyAccepted) || !event.PIIprivacy.requirePrivacyCheckbox" type="submit" variant="success" v-bind:style="{
                          'background-color': event.buttonBackgroundColor
                            ? event.buttonBackgroundColor
                            : '',
                          'border-color': event.buttonBackgroundColor 
                            ? event.buttonBackgroundColor 
                            : '',
                          'color': event.buttonTextColor
                            ? event.buttonTextColor
                            : '',
                        }">{{
                            t(event.PIIprivacy.registerbutton) || "Register"
                          }}</b-button>
                        </div>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-container>
              </b-aspect>
            </b-card-text>
          </b-card>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" :md="(event.chatIframe && state == 'live' && (!isIFrame || chatOnly)) ? 8 : 12">
        <div
          v-if="
            state == 'live' &&
            ((event.requirePII && user.PII) || !event.requirePII) && !chatOnly
          "
          class="player"
        >
          <div v-if="event.sources.length > 1">
            <b-button-group class="mb-2 language_buttons">
              <b-button
                v-on:click="setSourceVideoJS(language.url)"
                v-for="language in event.sources"
                :key="language.language_code"
                :class="{'text-uppercase': true, 'sourcebutton-color-active': currentSrc == language.url, 'sourcebutton-color-not-active': currentSrc != language.url }"
              >
                {{ language.language_name }}
              </b-button>
            </b-button-group>
          </div>
          <!--<div id="playerdiv"></div>-->
          <video-player
            :eventname="eventname"
            :uid="uid"
            :currentSrcLang="currentSrcLang"
            :key="playerKey"
            v-if="videoOptions"
            :options="videoOptions"
            :audioLanguageHint="videoLocaleHint"
          />
        </div>
        <div
          v-if="
            state == 'liveIframe' &&
            ((event.requirePII && user.PII) || !event.requirePII)
          "
          class="playerIframe embed-responsive embed-responsive-16by9"
        >
          <iframe id="ytplayer" class="embed-responsive-item" type="text/html"
            :src="event.iframeUrl"
            frameborder="0" allowfullscreen allow="autoplay"></iframe>
        </div>
      </b-col>
      <b-col v-if="event.chatIframe && event.state == 'live' && (!isIFrame || chatOnly)" cols="12" :md="chatOnly ? 12 : 4" :style="{'min-height':50+'vh', 'height': chatOnly ? '100vh': 'auto'}" class="mt-4 mt-md-0">
        <iframe :src="event.chatIframeUrl" width="100%" height="100%"></iframe>
      </b-col>
    </b-row>
    <b-row v-if="!isIFrame" class="my-2">
      <b-col>
        <div
          class="description"
          v-if="event.description && event.description.show" v-bind:style="{
                          'background-color': event.cardBackgroundColor
                            ? event.cardBackgroundColor
                            : '#ffffff',
                          'border-color': event.cardBorderColor 
                            ? event.cardBorderColor 
                            : '',
                          color: event.description.textColor
                            ? event.description.textColor
                            : '#000000',
                        }"
        >
          <b-card :title="t(event.description.title)">
            <b-card-text>
              {{ t(event.description.text) }}
            </b-card-text>
          </b-card>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="!isIFrame">
      <b-col>
        <div
          v-if="
            event.questions &&
            ((event.requirePII && user.PII) || !event.requirePII)
          "
          class="mt-4 questions"
          v-bind:style="{
                          'background-color': event.cardBackgroundColor
                            ? event.cardBackgroundColor
                            : '#ffffff',
                          'border-color': event.cardBorderColor 
                            ? event.cardBorderColor 
                            : ''
                        }"
        >
          <h5 class="text-center mb-2">
            {{
              t(
                event.questionsconfig
                  ? event.questionsconfig.yourquestionstitle
                  : null
              ) || "Your questions"
            }}
          </h5>
          <b-list-group>
            <b-list-group-item
              class="d-flex justify-content-between align-items-center"
              v-for="question in questions"
              :key="question.id"
              style="color: #000000"
            >
              {{ question.question.text }}

              <b-icon
                icon="x-circle"
                class="rounded-circle bg-danger"
                variant="light"
                v-on:click="deleteQuestion(question.id)"
              ></b-icon>
            </b-list-group-item>
          </b-list-group>

          <b-form
            class="mt-4"
            @submit="sendQuestion"
            v-if="event.questionsopened ? event.questionsopened : false"
          >
            <b-row>
              <b-col md="6">
                <b-form-group id="input-group-2">
                  <b-form-input
                    id="input-2"
                    v-model="questionform.name"
                    :disabled="user.PII"
                    :placeholder="
                      (user && user.name) ? user.name + ' ' + user.surname :
                      t(
                        event.questionsconfig
                          ? event.questionsconfig.nametext
                          : null
                      ) ||
                      'Enter name'
                    "
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  id="input-group-1"
                  :description="
                    t(
                      event.questionsconfig
                        ? event.questionsconfig.emaildisclaimer
                        : null
                    ) || ''
                  "
                >
                  <b-form-input
                    id="input-1"
                    v-model="questionform.email"
                    :disabled="user.PII"
                    type="email"
                    :placeholder="
                      user.email ||
                      t(
                        event.questionsconfig
                          ? event.questionsconfig.emailtext
                          : null
                      ) ||
                      'Enter email (not required)'
                    "
                    
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-form-group id="input-group-3">
              <b-form-textarea
                id="input-3"
                size="md"
                required
                :placeholder="
                  t(
                    event.questionsconfig
                      ? event.questionsconfig.questiontext
                      : null
                  ) || 'Enter your question'
                "
                v-model="questionform.question"
              ></b-form-textarea>
            </b-form-group>

            <div class="text-right">
              <b-button type="submit" variant="success" v-bind:style="{
                          'background-color': event.buttonBackgroundColor
                            ? event.buttonBackgroundColor
                            : '#ffffff',
                          'border-color': event.buttonBackgroundColor 
                            ? event.buttonBackgroundColor 
                            : '',
                          'color': event.buttonTextColor
                            ? event.buttonTextColor
                            : '',
                        }">{{
                t(
                  event.questionsconfig ? event.questionsconfig.askbutton : null
                ) || "Ask"
              }}</b-button>
            </div>
          </b-form>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="PII" v-if="user.name">
          {{ t(event.PIIprivacy.loggedintext) || "Stai visualizzando come: "
          }}<b>{{ user.name }} {{ user.surname }}</b> (<a
            href="#"
            @click.prevent="logOut()"
            >{{ t(event.PIIprivacy.logoutbutton) || "ESCI" }}</a
          >)
        </div>
      </b-col>
    </b-row>
    <div v-if="!isIFrame && event.localizations.length > 1" class="my-5 d-flex justify-content-end">
      <small><b-form-group
        id="language-selector-group"
        label="Website language:"
        label-for="language-selector"
        class="text-right"
      >
        <b-form-select v-model="locale" size="sm" id="language-selector" class="w-auto text-uppercase font-weight-bold" :options="event.localizations"></b-form-select>
      </b-form-group></small>
    </div>
  </b-container>
</template>

<style>
/*@import "https://cdn.myth.theoplayer.com/f7d7a378-012f-4a04-a037-4af7c65bd03d/ui.css";*/

/* Customization css style 
.theo-primary-color,
.vjs-selected {
  color: #ace1e2 !important;
}

.theo-primary-background {
  color: #000000 !important;
  background-color: #ace1e2 !important;
}

.theo-secondary-color {
  color: #ffffff !important;
}

.theo-secondary-background {
  color: #000000 !important;
  background-color: #ffffff !important;
}

.theo-tertiary-color {
  color: #000000 !important;
}

.theo-tertiary-background {
  color: #ffffff !important;
  background-color: #000000 !important;
}
*/

.jw-icon.jw-icon-tooltip.jw-icon-volume.jw-button-color.jw-reset.jw-off {
color: red;
}

.jw-icon.jw-icon-tooltip.jw-icon-volume.jw-button-color.jw-reset.jw-off.jw-open {
color:  red;
}

/*.player,
.coming,
.description,
.PII
.questions {
  max-width: 800px;
  margin: 0 auto;
  padding: 1.5em;
}*/

.alert {
  max-width: 800px;
  margin: 0 auto;
  padding: 1.5em;
}

.btn.active {
  background-color: #ace1e2 !important;
  color: black !important;
}

.player {
  position: relative;
}

.playerIframe {
  position: relative;
}

.language_buttons {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  z-index: 10000;
  display: none;
}

.player:hover > div > .language_buttons {
    display: block;
}

.player:focus > div > .language_buttons {
    display: block;
}

.sourcebutton-color-active {
  background-color: #9f3634 !important;
  color: #e0e0e0 !important;
}
.sourcebutton-color-not-active {
  background-color: #e0e0e0 !important;
  color: #9f3634 !important;
}
</style>

<script>
import * as fb from "./../firebase";

import getBrowserLocale from "@/util/get-browser-locale";
import VideoPlayer from "@/components/VideoPlayer.vue";
import Countdown from "@/components/Countdown.vue";

export default {
  data() {
    return {
      isIFrame: false,
      locale: "",
      authenticated: false,
      uid: "",
      user: {},
      formPII: {privacyPolicyAccepted: false},
      eventname: null,
      event: null,
      questions: [],
      state: false,
      player: null,
      currentSrc: null,
      currentSrcLang: null,
      currentAudioTrackLanguageCode: null,
      audioTracks: null,
      questionform: {
        email: "",
        name: "",
        question: "",
      },
      watchingLogInterval: null,
      videoOptions: null,
      playerKey: 0,
      chatOnly: false,
    };
  },
  computed: {
    // a computed getter
    countdown() {
      return Date.now()
    }
  },
  components: {
    VideoPlayer,
    Countdown,
  },
  mounted() {
    this.videoLocaleHint = this.$route.query.lc || getBrowserLocale() || "en";
    this.locale = this.$route.query.lc || getBrowserLocale() || "en";

    this.isIFrame = window.location !== window.parent.location;
    this.isIFrame ? document.getElementById("app").style.overflowY = "hidden" : null;

    this.chatOnly = this.$route.query.chatonly || false
    //LOAD PLAYER SCRIPTS
    /*let castFwScript = document.createElement("script");
    castFwScript.setAttribute(
      "src",
      "//www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1"
    );
    document.head.appendChild(castFwScript);*/

    /*let theoScript = document.createElement("script");
    theoScript.setAttribute(
      "src",
      "https://cdn.myth.theoplayer.com/f7d7a378-012f-4a04-a037-4af7c65bd03d/THEOplayer.js"
    );
    document.head.appendChild(theoScript);*/

    let jwScript = document.createElement("script");
    jwScript.setAttribute(
      "src",
      "https://cdn.jwplayer.com/libraries/Ac9ECv51.js"
    );
    document.head.appendChild(jwScript);

    //fb.auth.setPersistence(fb.authPersistence.SESSION)

    if (!fb.auth.currentUser) {
      fb.auth
        .signInAnonymously()
        .then(() => {
          this.authenticated = true;
          this.uid = fb.auth.currentUser.uid;
          this.getEventByUrl();
        })
        .catch((error) => {
          error.code;
          error.message;
          this.authenticated = false;
        });
    } else {
      this.authenticated = true;
      this.uid = fb.auth.currentUser.uid;
      this.getEventByUrl();
    }
  },
  beforeDestroy() {
    this.watchingLogInterval ? clearInterval(this.watchingLogInterval) : "";
    if (this.player) {
      this.player.dispose();
    }
  },
  methods: {
    //method to get translation from the object
    t(p) {
      if (typeof p === "object" && p !== null) {
        if (p[this.locale] !== undefined && p[this.locale] !== "") {
          return p[this.locale];
        } else if (p['en'] !== undefined && p['en'] !== "") {
          return p['en'];
        } else {
          return p[Object.keys(p)[0]];
        }
      } else {
        return p;
      }
    },
    logOut() {
      fb.auth
        .signOut()
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          console.log("error during logout: ", error);
        });
    },
    updateUsersData(PII = false) {
      var docRef = fb.db
        .collection("tenants")
        .doc(this.$route.params.tenantname)
        .collection("events")
        .doc(this.eventname)
        .collection("viewers")
        .doc(fb.auth.currentUser.uid);

      docRef
        .get()
        .then((doc) => {
          if (
            doc.exists &&
            doc.data().last_tenant != this.$route.params.tenantname
          ) {
            this.logOut();
          } else {
            let usersData = {};
            if (PII) {
              usersData = {
                name: this.formPII.name,
                surname: this.formPII.surname,
                email: this.formPII.email,
                last_update: fb.serverTs,
                last_tenant: this.$route.params.tenantname,
                extrafield1: this.formPII.extrafield1 || null,
                extrafield2: this.formPII.extrafield2 || null,
                extrafield3: this.formPII.extrafield3 || null,
                extrafield4: this.formPII.extrafield4 || null,
                extrafield5: this.formPII.extrafield5 || null,
                privacyPolicyAccepted: this.formPII.privacyPolicyAccepted || null,
                PII: true,
              };
            } else {
              usersData = {
                last_update: fb.serverTs,
                last_tenant: this.$route.params.tenantname,
              };
            }
            docRef
              .set(usersData, { merge: true })
              .then(function () {
                //
              })
              .catch(function (error) {
                console.log("Error updating document: ", error);
              });
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    },
    getEventByUrl() {
      fb.db
        .collection("tenants")
        .doc(this.$route.params.tenantname)
        .collection("event_links")
        .where("url_name", "==", this.$route.params.eventname)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.eventname = doc.data().event_ref;
          });
          this.startFb();
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
          this.eventname = this.$route.params.eventname;
        });
    },
    startFb() {
      this.updateUsersData();

      fb.db
        .collection("tenants")
        .doc(this.$route.params.tenantname)
        .collection("events")
        .doc(this.eventname)
        .collection("viewers")
        .doc(fb.auth.currentUser.uid)
        .onSnapshot((doc) => {
          if (doc.data()) {
            this.user = doc.data();
          }
        });
      fb.db
        .collection("tenants")
        .doc(this.$route.params.tenantname)
        .collection("events")
        .doc(this.eventname)
        .collection("config")
        .doc("0")
        .onSnapshot(
          {
            // Listen for document metadata changes
            includeMetadataChanges: true,
          },
          (doc) => {
            this.event = doc.data();
            this.switchEvent(this.event.state);
            this.event.localizations.find((l) => l == this.locale) ? null : this.locale = this.event.localizations[0]
          }
        );
      fb.db
        .collection("tenants")
        .doc(this.$route.params.tenantname)
        .collection("events")
        .doc(this.eventname)
        .collection("questions")
        .where("uid", "==", this.uid)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let question = { id: doc.id, question: doc.data() };
            this.questions.push(question);
          });
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    },
    switchEvent(state) {
      switch (state) {
        case "coming":
          if (this.state != "coming") {
            this.state = "coming";
            setTimeout(() => {
              this.destroyPlayerJW();
            }, 250);
          }
          break;
        case "live":
          if (this.state != "live") {
            this.state = "live";
            this.checkRequirePII();
          }
          break;
        case "liveIframe":
          if (this.state != "liveIframe") {
            this.state = "liveIframe";
            //this.checkRequirePII();
          }
          break;
        default:
          this.state = null;
          this.player = null;
          break;
      }
    },
    checkRequirePII() {
      if (this.event.requirePII) {
        if (this.user.PII) {
          this.state = "live";
          if(!this.chatOnly) {
            setTimeout(() => {
              this.loadPlayerVideoJS(this.event.sources[0].url);
            }, 250);
          }
        }
      } else {
        this.state = "live";

        if(!this.chatOnly) {
            setTimeout(() => {
              this.loadPlayerVideoJS(this.event.sources[0].url);
            }, 250);
          }
      }
    },
    loadPlayer() {
      if (this.player == null) {
        this.player = new window.THEOplayer.Player(
          document.getElementById("playerdiv"),
          {
            libraryLocation:
              "https://cdn.myth.theoplayer.com/f7d7a378-012f-4a04-a037-4af7c65bd03d",
            //mutedAutoplay: "all",
            license:
              "sZP7IYe6T6P60lXK3uPg3Zzc3S0LFSakCLR-CKBiIOzz3SezIu1lClC_0Qf6FOPlUY3zWokgbgjNIOf9fKBk3l0LIDaoFDaz3La-3Qak3Ok13DhZFDBZ3l0L3K0k0KBz0ZfVfK4_bQgZCYxNWoryIQXzImf90SCZTu5t0Sfi0u5i0Oi6Io4pIYP1UQgqWgjeCYxgflEc3l5rTuhL3SBL3S5ZFOPeWok1dDrLYtA1Ioh6TgV6UQ1gWtAVCYggb6rlWoz6FOPVWo31WQ1qbta6FOPqUorlCY3zFD41btepUo46FKXibOfVfKcNUQhpWtUpCoXLUOrVdYIgfgzVfKxqWDXNWG3ybojkbK3gflNWfGxEIDjiWQXrIYfpCoj-f6i6WQjlCDcEWt3zf6i6v6PUFOPLIQ-LflNWfK1zWDikfgzVfG3gWKxydDkibK4LbogqW6f9UwPkIYz",
          }
        );
      }
      // OPTIONAL CONFIGURATION

      // Customized video player parameters
      this.player.source = {
        sources: [
          {
            src: this.event.sources[0].url,
            type: "application/x-mpegurl",
            lowLatency: false,
          },
        ],
        // Poster image
        poster: this.event.player_poster_url,
      };
      this.player.autoplay = true;
      this.player.preload = "auto";

      this.player.addEventListener("destroy", () => {
        this.player = null;
      });

      this.player.addEventListener("sourcechange", () => {
        this.currentSrc = this.player.src;
        this.currentSrcLang = this.event.sources.find(
          (v) => v.url === this.player.src
        ).language_code;
      });

      this.player.addEventListener(
        [
          "durationchange",
          "stalled",
          "ended",
          "seeking",
          "seeked",
          "waiting",
          "playing",
          "pause",
          "volumechange",
        ],
        (event) => {
          fb.analytics.logEvent("video_event", {
            eventAction: event.type,
            videoSrc: this.player.src,
            eventValue: Math.floor(
              this.player.currentProgramDateTime || this.player.currentTime
            ),
          });
        }
      );

      this.player.addEventListener(["playing"], () => {
        this.watchingLogInterval = setInterval(() => {
          this.logWatching();
        }, 10000);
      });
      this.player.addEventListener(["pause", "waiting", "ended"], () => {
        this.watchingLogInterval ? clearInterval(this.watchingLogInterval) : "";
      });
    },
    destroyPlayer() {
      if (this.player) {
        this.player.destroy();
        this.watchingLogInterval ? clearInterval(this.watchingLogInterval) : "";
      }
    },
    loadPlayerJW() {
      if (this.player == null) {
        // eslint-disable-next-line
        this.player = jwplayer("playerdiv").setup({
          file: this.event.sources[0].url,
          autostart: "viewable",
          cast: {},
        });
        this.currentSrc = this.event.sources[0].url;

        /*this.player.on("remove", () => {
          this.player = null;
        });*/
        this.player.on("play", () => {
          if (!this.watchingLogInterval) {
            this.watchingLogInterval = setInterval(() => {
              this.logWatching();
            }, 10000);
            this.currentAudioTrackLanguageCode =
              this.player.getAudioTracks()[
                this.player.getCurrentAudioTrack()
              ].language;
          }
        });
        this.player.on("pause", () => {
          this.watchingLogInterval
            ? clearInterval(this.watchingLogInterval)
            : "";
          this.watchingLogInterval = null;
        });
        this.player.on("idle", () => {
          this.watchingLogInterval
            ? clearInterval(this.watchingLogInterval)
            : "";
          this.watchingLogInterval = null;
        });
        this.player.on("error", () => {
          this.watchingLogInterval
            ? clearInterval(this.watchingLogInterval)
            : "";
          this.watchingLogInterval = null;
        });
        this.player.on("complete", () => {
          this.watchingLogInterval
            ? clearInterval(this.watchingLogInterval)
            : "";
          this.watchingLogInterval = null;
        });
        this.player.on("audioTracks", (levels) => {
          this.audioTracks = levels;
        });
        this.player.on("audioTrackChanged", (track) => {
          this.currentAudioTrackLanguageCode =
            this.player.getAudioTracks()[track.currentTrack].language;
        });

        const buttonId = "change-audio-language-button";
        const iconPath = "/assets/audio-language-icon-4.png";

        // Call the player's `addButton` API method to add the custom button
        this.player.addButton(
          iconPath,
          null,
          this.toggleAudioTracksMenu,
          buttonId
        );
      }
    },
    toggleAudioTracksMenu() {
      setTimeout(function () {
        document
          .getElementsByClassName("jw-settings-audioTracks")[0]
          .dispatchEvent(new Event("click"));
      }, 10);
    },
    destroyPlayerJW() {
      if (this.player) {
        this.player.remove();
        this.watchingLogInterval ? clearInterval(this.watchingLogInterval) : "";
        this.player = null;
      }
    },
    loadPlayerVideoJS(url) {
      this.videoOptions = {
        autoplay: true,
        muted: false,
        poster: this.event.player_poster_url ? this.event.player_poster_url : null,
        controls: true,
        fluid: true,
        sources: [
          {
            src: url,
            type: "application/x-mpegURL",
            withCredentials: false,
            enableLowInitialPlaylist: false,
          },
        ],
        html5: {
          vhs: {
            overrideNative: true,
          },
          //nativeAudioTracks: false,
          //nativeVideoTracks: false,
        },
        plugins: {
          mux: {
            debug: false,
            data: {
              env_key: 'fkgdacp4s0fpu3844iath270c', // required
              // Metadata
              player_name: 'owncast',
              viewer_user_id: this.uid,
              video_id: this.eventname,
              video_title: this.$route.params.eventname,
              video_series: this.$route.params.tenantname,
            }
          },
        }
      };
      
      this.event.videoDownloadUrl ? this.videoOptions.plugins.vjsdownload = {
            beforeElement: 'playbackRateMenuButton',
            textControl: 'Download video',
            name: 'downloadButton',
            downloadURL: this.event.videoDownloadUrl
          } : null,

      this.playerKey += 1;
      this.currentSrc = url;
      this.currentSrcLang = this.event.sources.find(
        (v) => v.url === url
      ).language_code;
    },
    destroyPlayerVideoJS() {
      if (this.player) {
        this.player.dispose();
        this.watchingLogInterval ? clearInterval(this.watchingLogInterval) : "";
        this.player = null;
      }
    },
    logWatching() {
      console.log("log watching");
      let last_heartbeat_ts = Math.floor(Date.now() / 1000);

      //last log
      fb.db
        .collection("tenants")
        .doc(this.$route.params.tenantname)
        .collection("events")
        .doc(this.eventname)
        .collection("viewers")
        .doc(this.uid)
        .set(
          {
            last_heartbeat_ts: last_heartbeat_ts,
            last_src_lang: this.currentSrcLang,
            last_audio_track_language_code: this.currentAudioTrackLanguageCode,
            updated_at: fb.serverTs,
          },
          { merge: true }
        )
        .then(function () {
          //console.log(Math.floor(Date.now() / 1000));
        })
        .catch(function (error) {
          console.log("Error updating document: ", error);
        });

      //history log
      fb.db
        .collection("tenants")
        .doc(this.$route.params.tenantname)
        .collection("events")
        .doc(this.eventname)
        .collection("viewers")
        .doc(this.uid)
        .collection("history")
        .doc(last_heartbeat_ts.toString())
        .set(
          {
            last_heartbeat_ts: last_heartbeat_ts,
            last_src_lang: this.currentSrcLang,
            last_audio_track_language_code: this.currentAudioTrackLanguageCode,
            updated_at: fb.serverTs,
          },
          { merge: true }
        )
        .then(function () {
          //
        })
        .catch(function (error) {
          console.log("Error updating document: ", error);
        });

      //google analytics log
      fb.analytics.logEvent("video_event", {
        eventAction: "watching",
        videoSrc: this.player.src,
        videoTime: Math.floor(
          this.player.currentProgramDateTime || this.player.currentTime
        ),
      });
    },
    queryViewers() {
      let date = new Date();
      date.setSeconds(date.getSeconds() - 90);
      let usercount = 9999;
      fb.db
        .collection("tenants")
        .doc(this.$route.params.tenantname)
        .collection("events")
        .doc(this.eventname)
        .collection("viewers")
        .where("updated_at", ">=", date)
        .get()
        .then((querySnapshot) => {
          console.log("query size: " + querySnapshot.size);
          usercount = querySnapshot.size;
          console.log("user count: " + usercount);
        });
    },
    setSource(url) {
      this.player.src = url;
      this.player.play();
    },
    setSourceJW(url) {
      this.destroyPlayerJW();
      setTimeout(() => {
        // eslint-disable-next-line
        this.player = jwplayer("playerdiv").setup({
          file: url,
          autostart: "viewable",
          cast: {},
        });
        //this.player.load();
        this.currentSrc = url;
        this.currentSrcLang = this.event.sources.find(
          (v) => v.url === url
        ).language_code;

        /*this.player.on("remove", () => {
            this.player = null;
          });*/
        this.player.on("play", () => {
          console.log("play event");
          this.watchingLogInterval
            ? clearInterval(this.watchingLogInterval)
            : "";
          this.watchingLogInterval = null;
          if (!this.watchingLogInterval) {
            this.watchingLogInterval = setInterval(() => {
              this.logWatching();
            }, 10000);
            this.currentAudioTrackLanguageCode =
              this.player.getAudioTracks()[
                this.player.getCurrentAudioTrack()
              ].language;
          }
        });
        this.player.on("pause", () => {
          this.watchingLogInterval
            ? clearInterval(this.watchingLogInterval)
            : "";
          this.watchingLogInterval = null;
        });
        this.player.on("idle", () => {
          this.watchingLogInterval
            ? clearInterval(this.watchingLogInterval)
            : "";
          this.watchingLogInterval = null;
        });
        this.player.on("error", () => {
          this.watchingLogInterval
            ? clearInterval(this.watchingLogInterval)
            : "";
          this.watchingLogInterval = null;
        });
        this.player.on("complete", () => {
          this.watchingLogInterval
            ? clearInterval(this.watchingLogInterval)
            : "";
          this.watchingLogInterval = null;
        });
        this.player.on("audioTracks", (levels) => {
          this.audioTracks = levels;
          if (levels.tracks.length > 1) {
            const buttonId = "change-audio-language-button";
            const iconPath = "/assets/audio-language-icon-4.png";

            // Call the player's `addButton` API method to add the custom button
            this.player.addButton(
              iconPath,
              null,
              this.toggleAudioTracksMenu,
              buttonId
            );
          }
        });
        this.player.on("audioTrackChanged", (track) => {
          this.currentAudioTrackLanguageCode =
            this.player.getAudioTracks()[track.currentTrack].language;
        });
      }, 250);
    },
    setSourceVideoJS(url) {
      this.destroyPlayerVideoJS();
      this.loadPlayerVideoJS(url);
      /*
      this.player.src({
        src: url,
        type: "application/x-mpegURL",
        withCredentials: true,
      });
      this.currentSrc = url;
      this.currentSrcLang = this.event.sources.find(
        (v) => v.url === url
      ).language_code;*/
    },
    sendQuestion() {
      event.preventDefault();

      var questionData = {
        uid: this.uid,
        name:
          (this.user && this.user.name) ? (this.user.name + " " + this.user.surname) : this.questionform.name,
        email: this.user.email || this.questionform.email,
        extrafield1: this.user.extrafield1 || null,
        extrafield2: this.user.extrafield2 || null,
        extrafield3: this.user.extrafield3 || null,
        extrafield4: this.user.extrafield4 || null,
        extrafield5: this.user.extrafield5 || null,
        text: this.questionform.question,
        timestamp: Date.now(),
        server_ts: fb.serverTs,
      };

      fb.db
        .collection("tenants")
        .doc(this.$route.params.tenantname)
        .collection("events")
        .doc(this.eventname)
        .collection("questions")
        .add(questionData)
        .then((docRef) => {
          let question = { id: docRef.id, question: questionData };
          this.questions.push(question);
          this.questionform.question = "";
        })
        .catch((error) => {
          console.error("Error adding new question:: ", error);
        });
    },
    deleteQuestion(questionid) {
      fb.db
        .collection("tenants")
        .doc(this.$route.params.tenantname)
        .collection("events")
        .doc(this.eventname)
        .collection("questions")
        .doc(questionid)
        .delete()
        .then(() => {
          //console.log("Question successfully deleted!");
          const indx = this.questions.findIndex((v) => v.id === questionid);
          this.questions.splice(indx, indx >= 0 ? 1 : 0);
        })
        .catch(function (error) {
          console.error("Error removing question: ", error);
        });
    },
    orderSources(a, b) {
      if (a.language_code < b.language_code) {
        return -1;
      }
      if (a.language_code < b.language_code) {
        return 1;
      }
      return 0;
    },
    acceptPrivacy: function() {
    this.formPII.privacyPolicyAccepted = true;
    this.$refs['embeddedPrivacyModal'].hide()
    },
    rejectPrivacy: function() {
    this.formPII.privacyPolicyAccepted = false;
    this.$refs['embeddedPrivacyModal'].hide()
    },
    setCustomCss: function(cssString) {
      const customCssExists = document.getElementById("customCss");
      if (customCssExists) {
        customCssExists.remove();
      }
      if (cssString != null) {
        const style = document.createElement("style");
        style.innerHTML = cssString;
        style.id="customCss"
        document.head.appendChild(style);
      }
    }
  },
  watch: {
    event: function () {
      this.event.title
        ? (document.title = this.t(this.event.title))
        : (document.title = "Owncast");
      this.event.sources.sort(this.orderSources);
      this.setCustomCss(this.event.customCSS);
    },
    user: function (nv, ov) {
      if (ov.PII != nv.PII) {
        this.event && this.event.requirePII
          ? this.state == "live"
            ? this.checkRequirePII()
            : null
          : null;
      }
      if (nv.last_tenant != this.$route.params.tenantname) {
        this.logOut();
      }
    },
  },
};
</script>

